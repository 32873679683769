import React from 'react'
import Link from 'next/link'
import { storyLink, applyFastlyParams } from 'utils'
import PlayButtonIcon from 'icons/PlayButton'
import Image from 'next/image'
import { track } from 'lib/whoami'
import { storyTypeCDPMap } from '../../utils/stories'

const VideoPlayerLink = ({ story }) => {
  const image =
    story.post_type_meta.barstool_original && story.post_type_meta.barstool_original.image
      ? story.post_type_meta.barstool_original.image
      : story.thumbnail.raw_featured
      ? story.thumbnail.raw_featured
      : story.thumbnail.raw_desktop

  const imageSrc = applyFastlyParams(image, { crop: '16:9' })

  const handleVideoPlayerCDPEvent = () => {
    if (!story) return
    track('ContentClicked', {
      content: {
        src: story?.url,
        type: storyTypeCDPMap[story?.type],
        barstoolBrandID: story?.brand_id ? String(story?.brand_id) : undefined,
        barstoolTalentID: String(story?.author?.id),
        barstoolPodcastID: story?.post_type_meta?.podcast?.id ? String(story?.post_type_meta?.podcast?.id) : undefined,
        barstoolPostID: String(story?.id),
        imageSrc: story?.thumbnail?.featured,
        componentName: 'VideoPlayerLink'
      }
    })
  }

  return (
    <>
      <Link {...storyLink(story)} legacyBehavior>
        <a onClick={handleVideoPlayerCDPEvent} className='videoPlayerLink'>
          <span className='videoPlayerLink__play'>
            <span>
              <PlayButtonIcon width='35px' height='auto' />
            </span>
          </span>
          <Image
            className='videoPlayerLink__image'
            width={0}
            height={0}
            sizes='(max-width: 1400px) 75vw, 700px'
            src={imageSrc}
            alt={story.title}
            style={{ width: '100%', height: 'auto' }}
            priority={true}
          />
        </a>
      </Link>
      <style jsx global>
        {`
          .videoPlayerLink__image {
            width: 100%;
            height: auto;
          }
        `}
      </style>
      <style jsx>{`
        .videoPlayerLink {
          display: block;
          position: relative;
          overflow: hidden;
          cursor: pointer;
        }
        .videoPlayerLink__play {
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 110px;
          height: 110px;
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.45);
          border: 4px solid #fff;
        }
        .videoPlayerLink__play span {
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 38px;
        }
      `}</style>
    </>
  )
}

export default VideoPlayerLink
