import React from 'react'
import Link from 'next/link'
import { truncateString, THEME } from 'utils'
import { videosPageEvents } from 'lib/ga'
import VideoPlayerLink from 'components/VideoPlayerLink'

const FeaturedVideo = ({
  items,
  live = false,
  videosPage = false,
  background = 'linear-gradient(180deg, #000 0, #393939)'
}) => {
  if (!items.length) return null
  const item = items[0]
  const getLabel = () => {
    if (live) {
      return item.post_type_meta.live.is_live ? 'Live Now' : 'Starting Soon'
    } else {
      return item.brand_name || 'New'
    }
  }
  const handleHeroClick = () => {
    if (videosPage) {
      videosPageEvents.heroClicked(item.url)
    }
  }
  return (
    <section className={`featuredVideo  ${live ? 'featuredVideo--live' : ''}`} style={{ background }}>
      <div className='container'>
        <div className='row'>
          <div className='col col--left'>
            <div className='featuredVideo__content'>
              <p className='featuredVideo__label'>{getLabel()}</p>
              <h2 className='featuredVideo__title'>{truncateString(item.title, 115)}</h2>
              {live && (
                <Link href='/live/[id]/[slug]' as={`/live/${item.id}/${item.slug}`} legacyBehavior>
                  <a className='btn btn--primary'>Watch Now</a>
                </Link>
              )}
            </div>
          </div>
          <div className='col col--right'>
            <div className='featuredVideo__video' onClick={handleHeroClick}>
              <VideoPlayerLink story={item} />
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .col--left,
        .col--right {
          min-width: 100%;
          flex-basis: 100%;
        }
        .col--left {
          order: 1;
        }
        .col--right {
          order: 0;
        }
        .featuredVideo {
          margin-top: ${videosPage ? '-20px' : '-25px'};
        }
        .featuredVideo__content {
          display: flex;
          height: 100%;
          flex-direction: column;
          justify-content: center;
          padding: 1rem 0;
        }
        .featuredVideo__label,
        .featuredVideo__title {
          font-family: ${THEME.FONTS.HELVETICA};
          font-weight: 700;
        }
        .featuredVideo__label {
          font-size: 16px;
          color: #999;
          letter-spacing: 0.7px;
          text-transform: uppercase;
          margin: 0;
        }
        .featuredVideo--live .featuredVideo__label {
          color: ${THEME.COLORS.PRIMARY};
          margin-bottom: 10px;
        }
        .featuredVideo__title {
          font-size: 24px;
          color: #fff;
          margin: 0;
          letter-spacing: -0.95px;
          text-transform: none;
        }
        .featuredVideo__video {
          margin: 0 -15px;
        }
        .btn--primary {
          display: none;
        }
        @media (max-width: ${THEME.BREAKPOINTS.XS}px) {
          .featuredVideo__video {
            margin: 0 -8px;
          }
        }
        @media (max-width: ${THEME.BREAKPOINTS.SM - 1}px) {
          .featuredVideo {
            margin-top: 0px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          .featuredVideo__video {
            margin: 0;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .col--left {
            order: 0;
            min-width: calc(100% / 3);
            flex-basis: calc(100% / 3);
          }
          .col--right {
            order: 1;
            min-width: calc(100% / 1.5);
            flex-basis: calc(100% / 1.5);
          }
          .featuredVideo {
            margin-bottom: 32px;
          }
          .featuredVideo__content {
            padding: 0 0 32px;
          }
          .featuredVideo__title {
            font-size: 36px;
          }
          .featuredVideo__video {
            box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2);
            position: relative;
            bottom: -2rem;
          }
          .btn--primary {
            align-self: flex-start;
            margin-top: 15px;
            padding: 10px 50px;
            display: inline-block;
            width: auto;
          }
        }
      `}</style>
    </section>
  )
}

export default FeaturedVideo
